@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.assignment-card {
  display: flex;
  padding: 12px 16px;
  border: 1px solid $neutral1;
  border-radius: 12px;
  background-color: $neutral0;
  column-gap: 8px;
  cursor: pointer;

  &-tooltip {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .assignment-card__index-wrap {
      padding-top: 0;
    }
  }

  &--selected, {
    background-color: $neutral1;
    cursor: auto;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 30%;
  }

  &__index {
    @include footnote($neutral3);

    &-wrap {
      min-width: 35px;
      padding-top: 4px;
    }
  }

  &__icon {
    padding-top: 4px;
    .ccm-svg-inline {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include body-regular($neutral5);
    margin-bottom: 4px;
  }

  &__description {
    @include description-regular($neutral4);
  }
}
