@import "src/app/styles/variables";
@import "src/app/styles/mixins";

.header {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;

  &-app {
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $neutral5;
    padding: 12px 32px;

    .header-app,
    .credits-view,
    .header-user {
      flex: 1;
    }

    .credits-view {
      justify-content: center;
    }

    .header-user {
      justify-content: flex-end;
      column-gap: 16px;
    }
  }

  &__logo {
    display: inline-flex;
    width: 32px;
    height: 32px;

    &--industryrockstarlms {
      border-radius: 2px;
      background-color: $white;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
  }

  &__title {
    @include description-regular($neutral0);
    margin: 0;
    letter-spacing: 0.1em;
    font-weight: 700;
    text-transform: none;
  }

  &__mid {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .cb-button {
      background-color: $white !important;
    }
  }
}
