
.assign-reading {

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
  }

  &-content__wrap {
    max-width: 908px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-bottom: 12px;
    height: 100%;
    padding-inline-end: 8px;
  }
}


