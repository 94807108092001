
.get-expert-bnt {
  position: fixed;
  bottom: 17px;
  left: 170px;
  z-index: 1000;

  &--first-position {
    left: 50px;
  }
}
